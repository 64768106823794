export default {
  data () {
    return {
      conversionRef: {
        inches: {
          cm: 2.54
        },
        cm: {
          inches: 0.393701
        },
        feet: {
          metres: 0.3048
        },
        metres: {
          feet: 3.28084
        },
        sf: {
          acres: 2.29568e-5,
          m2: 0.092903,
          hectares: 9.2903e-6
        },
        m2: {
          sf: 10.7639,
          hectares: 0.0001,
          acres: 0.000247105
        },
        acres: {
          hectares: 0.404686
        },
        yd3: {
          m3: 0.764555
        },
        m3: {
          yd3: 1.30795,
          FBM: 423.776
        },
        'kg/sf': {
          'kg/m2': 10.764,
          'lb/sf': 2.20462
        },
        'kg/m2': {
          'kg/sf': 0.092903
        },
        lbs: {
          kg: 0.453592,
          tons: 0.005
        },
        lb: {
          kg: 0.453592,
          tons: 0.005
        },
        kg: {
          lbs: 2.20462,
          tonnes: 0.001,
          tons: 0.00110231
        },
        tons: {
          tonnes: 0.907185
        },
        tonnes: {
          tons: 1.10231,
          kg: 1000,
          lbs: 2204.62,
          lb: 2204.62
        },
        FBM: {
          m3: 0.00235974
        }
      },
      eqRef: {
        inches: 'cm',
        cm: 'inches',
        feet: 'metres',
        metres: 'feet',
        sf: 'm2',
        m2: 'sf',
        yd3: 'm3',
        m3: 'yd3',
        lb: 'kg',
        lbs: 'kg',
        kg: 'lbs',
        tons: 'tonnes',
        tonnes: 'tons',
        'kg/sf': 'kg/m2',
        'kg/m2': 'kg/sf',
        FBM: 'm3'
      }
    }
  },
  computed: {
    areaUnits () {
      return this.getUnit('m2', true)
    }
  },
  methods: {
    convertUnits (val, inputUnits, outputUnits) {
      var o = outputUnits || this.eqRef[inputUnits]
      if (this.conversionRef[inputUnits] && this.conversionRef[inputUnits][o]) {
        return val * this.conversionRef[inputUnits][o]
      } else {
        return false
      }
    },
    conv ({ val, inp }, outputUnits) {
      return this.convertUnits(val, inp, outputUnits)
    },
    getUnit (inp, short) {
      const imperial = this.project.systemOfMeasurement !== 'metric'
      switch (inp) {
        case 'm2':
          if (imperial) return short ? 'ft²' : 'sf feet'
          else return short ? 'm²' : 'sq meters'
        case 'sf':
          if (imperial) return short ? 'ft²' : 'sf feet'
          else return short ? 'm²' : 'sq meters'
        case 'kg':
          if (imperial) return 'lbs'
          else return 'kg'
        case 'tonnes':
          if (imperial) return 'lbs'
          else return 'kg'
        default:
          break
      }
    },
    getUnitVal (val, inp, noSizeConvert) {
      var conv = this.conv
      let v, u
      var d = { val, inp }
      var imperial = this.project.systemOfMeasurement !== 'metric'
      switch (inp) {
        case 'm2':
          if (imperial) {
            var sqf = conv(d, 'sf')
            v = Math.abs(sqf) < 43560 || noSizeConvert ? sqf : conv(d, 'acres')
            u = Math.abs(sqf) < 43560 || noSizeConvert ? 'sq feet' : 'acres'
          } else {
            v = Math.abs(val) < 10000 || noSizeConvert ? val : conv(d, 'hectares')
            u = Math.abs(val) < 10000 || noSizeConvert ? 'sq metres' : 'hectares'
          }
          break
        case 'sf':
          if (imperial) {
            v = Math.abs(val) < 43560 || noSizeConvert ? val : conv(d, 'acres')
            u = Math.abs(val) < 43560 || noSizeConvert ? 'sq feet' : 'acres'
            break
          } else {
            var sqm = conv(d, 'm2')
            v = Math.abs(sqm) < 10000 || noSizeConvert ? sqm : conv(d, 'hectares')
            u = Math.abs(sqm) < 10000 || noSizeConvert ? 'sq metres' : 'hectares'
            break
          }
        case 'kg':
          if (!imperial) {
            v = Math.abs(val) < 1000 || noSizeConvert ? val : conv(d, 'tonnes')
            u = Math.abs(val) < 1000 || noSizeConvert ? 'kg' : 'tonnes'
            break
          } else {
            var lbs = conv(d, 'lbs')
            v = Math.abs(lbs) < 2000 || noSizeConvert ? lbs : conv(d, 'tons')
            u = Math.abs(lbs) < 2000 || noSizeConvert ? 'lbs' : 'tons'
            break
          }
        case 'tonnes':
          if (!imperial) {
            v = Math.abs(val) >= 1000 || noSizeConvert ? val : conv(d, 'kg')
            u = Math.abs(val) >= 1000 || noSizeConvert ? 'tonnes' : 'kg'
            break
          } else {
            var tons = conv(d, 'tons')
            v = Math.abs(tons) >= 2000 || noSizeConvert ? tons : conv(d, 'lbs')
            u = Math.abs(tons) >= 2000 || noSizeConvert ? 'tons' : 'lbs'
            break
          }
        default:
          break
      }
      let str = v.toString()
      const length = str.includes('.') ? str.substr(0, str.indexOf('.')).length : str.length
      let p = Math.max(4 - length, 0)
      p = Math.pow(10, p)
      str = Math.round(v * p) / p
      str = str.toLocaleString() + ' ' + u
      return {
        val: v,
        units: u,
        str
      }
    }
  }
}
