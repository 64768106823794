import { render, staticRenderFns } from "./VersionListItem.vue?vue&type=template&id=56be2ce6&scoped=true&"
import script from "./VersionListItem.vue?vue&type=script&lang=js&"
export * from "./VersionListItem.vue?vue&type=script&lang=js&"
import style0 from "./VersionListItem.vue?vue&type=style&index=0&id=56be2ce6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56be2ce6",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VBtn,VIcon,VSelect,VTextarea,VTooltip})
