var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('article',{staticClass:"version-list-item onyx--text",style:(_vm.$vuetify.breakpoint.xsOnly && 'padding-left: 0; padding-bottom: 10px;')},[_c('header',{staticClass:"header"},[_c('div',{staticClass:"item-headline"},[(_vm.$vuetify.breakpoint.smAndUp)?_c('span',{staticClass:"dot"}):_vm._e(),_c('div',[_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":{ path: ("/projects/" + (_vm.project._id) + "/edit"), query: { version: _vm.version._id }}}},[_c('h1',{staticClass:"title-text"},[_vm._v(" "+_vm._s(_vm.version.title)+" ")]),_c('h1',{staticClass:"title-text"},[_vm._v(" "+_vm._s(_vm.versionText)+" ")])]),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticClass:"type text-capitalize shamrock--text"},_vm.type === 'baseline design' ? on : null),[_vm._v(" "+_vm._s(_vm.type)+" ")])]}}])},[_vm._v(" "+_vm._s(_vm.type === 'baseline design' ? 'Contains data for the current state of your project' : 'Add data for planned improvements to your project')+" ")])],1),_c('ItemOptionsMenu',{attrs:{"tooltip":"Options","options":[
          { icon: 'mdi-delete', color: 'red', text: 'Delete version', value: 'delete' },
          { icon: 'mdi-content-duplicate', color: 'metal', text: 'Duplicate version', value: 'duplicate' },
          { icon: 'mdi-repeat', color: 'metal', text: ("Make this a " + (_vm.type === 'design alternative' ? 'baseline design' : 'design alternative')), value: 'toggle' }
        ]},on:{"option-clicked":_vm.handleOptionClick}})],1),_c('div',{staticClass:"metadata"},[_c('span',{staticClass:"date"},[_vm._v(" Last modified "),_c('strong',[_vm._v(_vm._s(_vm.getDateMonthAndYear(new Date(_vm.version.lastModifiedDate))))])]),(_vm.user.isAdmin)?_c('v-select',{staticClass:"pt-1 pb-0",attrs:{"multiple":"","placeholder":"","items":[2, 3],"label":"Version Compatability"},on:{"change":_vm.handleCPDVersionChange},model:{value:(_vm.newCPDVersions),callback:function ($$v) {_vm.newCPDVersions=$$v},expression:"newCPDVersions"}}):_vm._e()],1)]),_c('main',{staticClass:"main"},[_c('div',{staticClass:"description"},[_c('p',{staticClass:"ma-0",style:(_vm.editingDescription && 'width: 100%;')},[(!_vm.editingDescription)?_c('span',[_vm._v(_vm._s(_vm.version.description || 'No description'))]):_c('v-textarea',{directives:[{name:"on-clickaway",rawName:"v-on-clickaway",value:(_vm.toggleEditingDescription),expression:"toggleEditingDescription"}],attrs:{"rows":"1","placeholder":"Enter a description","dense":""},model:{value:(_vm.newDescription),callback:function ($$v) {_vm.newDescription=$$v},expression:"newDescription"}})],1),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-1",attrs:{"icon":"","small":""},on:{"click":_vm.toggleEditingDescription}},on),[_c('v-icon',{attrs:{"small":"","dense":"","color":_vm.editingDescription ? 'shamrock' : 'inherit'}},[_vm._v(" "+_vm._s(_vm.editingDescription ? 'mdi-check' : 'mdi-pencil')+" ")])],1)]}}])},[_vm._v(" "+_vm._s(_vm.editingDescription ? 'Save changes' : 'Edit description')+" ")])],1)]),_c('footer',{staticClass:"actions"},[_c('div',{staticClass:"buttons"},[_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":{ path: ("/projects/" + (_vm.project._id) + "/edit"), query: { version: _vm.version._id }}}},[_c('v-btn',{staticClass:"detail button d-flex align-center mr-3",attrs:{"outlined":"","tile":""}},[_c('span',{staticStyle:{"font-size":"1.3em","margin-right":"5px","margin-top":"1px"}},[_vm._v("+")]),_vm._v(" Add detail ")])],1),(_vm.type === 'design alternative')?_c('v-btn',{staticClass:"detail button d-flex align-center mr-3",style:({ 'pointer-events': _vm.version.isActive ? 'none' : 'all' }),attrs:{"outlined":!_vm.version.isActive,"tile":"","color":"shamrock white--text","elevation":"0"},on:{"click":function($event){return _vm.$emit('make-primary')}}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v(" "+_vm._s(_vm.version.isActive ? 'mdi-lightbulb-on' : 'mdi-lightbulb-outline')+" ")]),_vm._v(" "+_vm._s(_vm.version.isActive ? ("" + (_vm.$vuetify.breakpoint.smAndUp ? 'Primary design' : 'Primary')) : ("" + (_vm.$vuetify.breakpoint.smAndUp ? 'Make primary design' : 'Primary')))+" ")],1):_vm._e(),_c('ScorecardDialog',{attrs:{"version-id":_vm.version._id,"tiled-button":""}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }